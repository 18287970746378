<template>
  <div>
    <el-container>
      <el-header>
        <div class="EditTitle">修改个人信息</div>
      </el-header>
      <el-main>
          <el-row>
            <el-col :span="4">
              <div class="Avatar">
                  <div class="UserAvatar">
                      <img :src="usericon" >
                      <div class="AvatarMask" @click="ChangeAvatar">更换头像</div>                    
                  </div> 
                  <el-input v-model="usericon" placeholder="请输入头像超链接地址" :disabled=avatarstatus></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="UserNickName">
                  <el-input v-model="nickname" placeholder="请输入内容" :disabled=inputstatus class="EditDiv"></el-input>
                  <el-link type="primary" @click="OMEditNickName" :underline="false">修改昵称</el-link>
              </div>
            </el-col>
          </el-row>
      </el-main>
      <el-footer>
        <el-button type="primary" class="SaveInfo" @click="_SaveInfo">保存</el-button>
      </el-footer>
    </el-container>
    <input type="file" style="display:none" id="avatar">
  </div>
</template>

<script>
import {GetUserNickName,UpdateUserInfo,UploadLocalImage} from "@/api/user.js"
import '@/assets/rem.js'
import { Message } from "element-ui";

export default {
    name:'Setting',
    data(){
      return{
          nickname:'',
          usericon:'',
          nicklink:'',
          inputstatus:true,
          avatarstatus:true,
          ChangeAvatarVisible:false,
      }
    },
    created(){
      this._GetUserInfo()
    },
    methods:{
      _GetUserInfo(){
        GetUserNickName().then(res=>{
          this.nickname = res.data.nickname
          this.usericon = res.data.icon
        })
      },
      OMEditNickName(){
        this.inputstatus = false
      },
      ChangeAvatar(){
        let _this = this
        let Dom = document.getElementById('avatar')
        Dom.click()
        Dom.onchange = function(){
          let file = this.files[0]
          let formData = new FormData()
          formData.append('imageData',file)
          UploadLocalImage(formData).then(res=>{
            console.log(res)
            console.log(res.data)
            Message({type: "success",message: "上传成功"});
            _this.usericon = res.data
          })
        }
      },
      _SaveInfo(){
        let infodata={
          'icon':this.usericon,
          'nickname':this.nickname
        }
        UpdateUserInfo(infodata).then(res=>{
          if(res.code == 0){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this._GetUserInfo()
          }
        })
      }
    }
}
</script>

<style scoped>
.EditTitle{
  color: white;
  height: 100%;
  font-size: 0.24rem;
  font-weight: 500;
}
.Avatar{
    position: relative;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.UserAvatar{
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 10;
    display: flex;
}
.UserAvatar:hover .AvatarMask{
  display: block;
}
.AvatarMask{
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 0.4rem;
  background-color: #c9c9c957;
  font-size: 0.14rem;
  line-height: 0.8rem;
  cursor: pointer;
  display: none;
}
.UserAvatar img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    cursor: pointer;
}
.UserNickName{
  display: flex;
  line-height: 0.8rem;
  text-align: left;
  font-size: 0.2rem;
  font-weight: 500;
}
.EditDiv{
  width: 50%;
}
.SaveInfo{
  position: absolute;
}
</style>