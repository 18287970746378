import http from '@/utils/http';

export function GetUserNickName(){
	return http.get('/v1/user')
}
export function UpdateUserInfo(params){
	return http.put('/v1/user',params)
}
export function UploadLocalImage(params){
	return http.post('/v1/file/image',params)
}
export function GetUserTrade(){
	return http.get('/v1/user/trade')
}
export function GetUserWallet(){
	return http.get('/v1/user/wallet')
}